export default {
    run (bilangan) {
        return bilangan.toString().replace(/[.]/mg, '').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onInput (bilangan) {
        let num = bilangan.toString().replace(/[.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        return num
    },
    parse (bilangan) {
        return bilangan.toString().replace(/[.]/mg, '')
    },
    parseFloat (bilangan) {
      return bilangan.toString().replace(/[.]/mg, '').replace(',', '.')
    },
    onFloat (bilangan) {
      return bilangan.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    checkDigit (number) {
      // let regEx = /(\d*\.\d+|\d+),?/g
      let regEx = /\d*[^a-zA-Z]$/g
      return regEx.test(number)
    }
}
