export default {
    /**
     * ==================================================
     * Konversi Decimal EN ke Decimal ID
     * --------------------------------------------------
     * value    = decimal berformat EN
     * nullable = true/false
     *            true, mengembalikan nilai '' jika value ''
     *            false, mengembalikan nilai 0 jika value ''
     * contoh :
     *  1000000.99  => 1000000,99
     */
     formatDecimalENtoID(value, nullable){
        if(value){
            // mengganti decimal . menjadi ,
            let valueID     = valueID.toString().replace('.', ',');
            return valueID;
        }else{
            if(!nullable){
                return 0;
            }
        }
    },
    /**
     * ==================================================
     * Konversi Decimal ID ke Decimal EN
     * --------------------------------------------------
     * value    = decimal berformat ID
     * nullable = true/false
     *            true, mengembalikan nilai '' jika value ''
     *            false, mengembalikan nilai 0 jika value ''
     * contoh :
     *  1000000,99  => 1000000.99
     */
     formatDecimalIDtoEN(value, nullable){
        if(value){
            // mengganti decimal , menjadi .
            let valueEN     = valueEN.toString().replace(',', '.'); 
            return valueEN;
        }else{
            if(!nullable){
                return 0;
            }
        }
    },

    /**
     * ==================================================
     * Konversi Number (Integer/Decimal) EN ke Currency ID
     * --------------------------------------------------
     * value    = bilangan (integer/decimal) berformat EN
     * nullable = true/false
     *            true, mengembalikan nilai '' jika value ''
     *            false, mengembalikan nilai 0 jika value ''
     * contoh :
     *  1000000.99  => 1000000,99
     */
     formatNumberENtoCurrencyID(value, desimalPlaces, nullable){
        const roundAccurately = (value, desimalPlaces) => 
            Number(Math.round(value + "e" + desimalPlaces) + "e-" + desimalPlaces)
        
        
        if(value){

            if(value<0){
                // jika nilai < 0 (minus)
                alert(-roundAccurately(Math.abs(value),desimalPlaces));
            }else if(value>0){
                // jika nilai > 0 (positif)
                alert(roundAccurately(value,desimalPlaces));
            }

            // konversi.onFloat(parseFloat(item.sub_total.diff_balance).toFixed(2))
            // konversi.onFloat((parseFloat(item.sub_total.diff_balance)).toFixed(2))

            // mengganti decimal . menjadi ,
            let valueID = valueID.toString().replace('.', ',');
            valueID     = valueID.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            

            return valueID;
        }else{
            if(!nullable){
                return 0;
            }
        }
    },

    
    /**
     * ==================================================
     * Mengubah format Currency dari EN ke ID
     * --------------------------------------------------
     * value = number berformat EN
     * contoh:
     *  1000000.99      => 1.000.000,99
     *  1,000,000.99    => 1.000.000,99
     */
    formatENtoID(value){
        if(value){
            let valueID = value ? value : 0;
            // if (valueID.indexOf('.') > -1){
            //     alert("hello found inside your_string");
            // }
            valueID     = valueID.toString().replace('.', ','); // mengganti decimal . menjadi ,
            valueID     = valueID.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return valueID;
        }else{
            // return 0;
        }
    },
    /**
     * ==================================================
     * Mengubah format Currency dari ID ke EN
     * --------------------------------------------------
     * value = number berformat ID
     * contoh:
     *  1000000,99      => 1,000,000.99
     *  1.000.000,99    => 1,000,000.99
     */
    formatIDtoEN(value){
        if(value){
            let valueEN = value ? value.toString().replaceAll('.','') : 0;
            valueEN     = valueEN.toString().replace(',', '.'); // mengganti decimal , menjadi .
            return valueEN;
        }else{
            // return 0;
        }
    },
}