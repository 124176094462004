<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Target" class="panel panel-success">

            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button class="pull-right" variant="primary" @click="create">
                                    <i class="fa fa-plus pr-1"></i> Tambah Data
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group :label-cols="3" label="Filter By" class="mb-1">
                                        <b-input-group size="md">
                                            <b-form-select v-model="filterBy" :options="fieldOptions"
                                                @change="changeFilterBy()">
                                                <option slot="first" :value="'all'">All Data</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group :label-cols="3" label="Filter" class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching ">
                                        <b-input-group size="md">
                                            <b-form-input v-model="filter"
                                                placeholder="Type to Search" debounce="500" />
                                            <b-input-group-append>
                                                <b-btn :disabled="!filter" @click="filter = ''" variant="info">Clear
                                                </b-btn>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group :label-cols="3" label="Sort" class="mb-1">
                                        <b-input-group size="md">
                                            <b-form-select v-model="sortBy" :options="fieldOptions">
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select :disabled="!sortBy" v-model="sortDesc" slot="append">
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->

                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table show-empty small responsive id="tableInduk" ref="tableInduk" head-variant="light" :hover="true"
                :busy.sync="isBusy" :items="myGetData" :fields="fields" :current-page="currentPage" :per-page="perPage"
                :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" bordered>
                <!--
                loading table
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!--
                pengaturan kolom
                -------------------------------------------------- -->
                <!-- header kolom -->
                <template #head()="data">
                    {{ data.label }}
                </template>
                <!-- lebar kolom -->
                <template v-slot:table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" :style="{
                            width: field.key === 'index' ? '35px' :
                                field.key === 'actions' ? '103px' : ''
                        }" />
                </template>
                <!--
                index (nomor baris)
                -------------------------------------------------- -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!--
                perangkat pd
                -------------------------------------------------- -->
                <template #cell(perangkat_pd_nama)="data">
                    {{ data.item.perangkat_pd.nama }}
                </template>
                <!--
                referensi rekening 5
                -------------------------------------------------- -->
                <!-- <template #cell(ref_rek_5_nama)="data">
                    {{ data.item.ref_rek_5.kode }}
                    <br>{{ data.item.ref_rek_5.nama }}
                </template> -->
                <!--
                referensi rekening 6
                -------------------------------------------------- -->
                <template #cell(ref_rek_6_nama)="data">
                    {{ data.item.ref_rek_6.kode }}
                    <br>{{ data.item.ref_rek_6.nama }}
                </template>
                <!--
                target apbd
                -------------------------------------------------- -->
                <template #cell(apbd)="data">
                    {{ data.item.apbd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                </template>
                <!--
                target apbd perubahan
                -------------------------------------------------- -->
                <template #cell(apbd_p)="data">
                    {{ data.item.apbd_p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                </template>
                <!--
                produk hukum
                -------------------------------------------------- -->
                <template #cell(produk_hukum)="data">
                    Nomor {{ data.item.produk_hukum.nomor }}
                    Tahun {{ data.item.produk_hukum.tahun }}
                    Tentang {{ data.item.produk_hukum.judul }}
                </template>
                <!--
                tombol aksi
                -------------------------------------------------- -->
                <template v-slot:cell(actions)="data">
                    <b-dropdown split size="sm" variant="info">
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="view(data.item.id)"><i class="fa fa-eye"></i>
                            Lihat</b-dropdown-item>
                        <b-dropdown-item @click="edit(data.item.id)"><i class="fa fa-edit"></i>
                            Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteConfirm(data.item.id, data.item)"><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select :options="pageOptions" style="width: 100px;" v-model="perPage" />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input :disabled="true" v-model="totalRows" style="width: 100px;"
                                class="text-right" />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                        size="md" class="my-0 "></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->

        </panel>

        <!--
        Modal untuk Form create() & edit()
        -------------------------------------------------- -->
        <b-modal :title="formModal.title" v-model="formModal.show" id="modalForm" hide-footer no-close-on-backdrop
            size="lg">
            <form @submit.prevent="actButton.edit ? update(dataPrevious.id) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off">
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Perangkat PD
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="options.perangkat_pd"
                                    :reduce="(option) => option.id"
                                    v-model="form.perangkat_pd_id"
                                    :disabled="actButton.type==='view'"
                                    placeholder="--- Pilih data ---"
                                >
                                    <template #option="{ id, kode, kode_pd, nama }">
                                        <span>
                                            <strong>ID : {{ id }}</strong>
                                            <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                            <br><div v-html="nama"></div>
                                        </span>
                                    </template>
                                    <template #selected-option="{ id, kode, kode_pd, nama }">
                                        <span>
                                            <strong>ID : {{ id }}</strong>
                                            <br><strong>Kode : {{ kode }} / {{kode_pd}}</strong>
                                            <br><div v-html="nama"></div>
                                        </span>
                                        <!-- <div style="display: flex; align-items: baseline">
                                            <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                            <em style="margin-left: 0.5rem"
                                            >{{ judul }}</em
                                            >
                                        </div> -->
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <em v-if="form.errors.has('perangkat_pd_id')"
                            class="form-text text-danger mt-0">
                            {{ formModal.errors.perangkat_pd_id[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Jenis Rekening
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="options.ref_rek_5"
                                    :reduce="(option) => option.id"
                                    v-model="form.ref_rek_5_id"
                                    :disabled="actButton.type==='view'"
                                    @input="getoptionRefRek6(form.ref_rek_5_id)"
                                    placeholder="--- Pilih data ---"
                                ></v-select>
                            </div>
                        </div>
                        <em v-if="form.errors.has('ref_rek_5_id')"
                            class="form-text text-danger mt-0">
                            {{ formModal.errors.ref_rek_5_id[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Rincian Rekening
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="options.ref_rek_6"
                                    :reduce="(option) => option.id"
                                    v-model="form.ref_rek_6_id"
                                    :disabled="actButton.type==='view'"
                                    placeholder="--- Pilih data ---"
                                ></v-select>
                            </div>
                        </div>
                        <em v-if="form.errors.has('ref_rek_6_id')"
                            class="form-text text-danger mt-0">
                            {{ formModal.errors.ref_rek_6_id[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Tahun
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-3">
                                <input type="text" :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('tahun'),
                                    }" v-model="form.tahun" :disabled="actButton.type==='view'" placeholder="" />
                            </div>
                        </div>
                        <em v-if="form.errors.has('tahun')" class="form-text text-danger mt-0">
                            {{ formModal.errors.tahun[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Target
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-4">
                                <b-form-input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'text-right': true,
                                        'is-invalid': form.errors.has('apbd'),
                                    }"
                                    v-model="form.apbd"
                                    @keypress="isNumberKey($event)"
                                    @blur="inputCurrency($event)"
                                    :disabled="actButton.type==='view'"
                                    placeholder="">
                                    <!-- @change="inputCurrency($event)" -->
                                </b-form-input>
                                <!-- <input type="text" :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('apbd'),
                                    }" v-model="form.apbd" :disabled="actButton.type==='view'" placeholder="" /> -->
                            </div>
                        </div>
                        <em v-if="form.errors.has('apbd')" class="form-text text-danger mt-0">
                            {{ formModal.errors.apbd[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Target Perubahan
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-4">
                                <b-form-input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'text-right': true,
                                        'is-invalid': form.errors.has('apbd_p'),
                                    }"
                                    v-model="form.apbd_p"
                                    @keypress="isNumberKey($event)"
                                    @blur="inputCurrency($event)"
                                    :disabled="actButton.type==='view'"
                                    placeholder="">
                                </b-form-input>
                            </div>
                        </div>
                        <em v-if="form.errors.has('apbd_p')" class="form-text text-danger mt-0">
                            {{ formModal.errors.apbd_p[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Dasar Hukum
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="judul"
                                    :options="options.produk_hukum"
                                    :reduce="(option) => option.id"
                                    v-model="form.produk_hukum_id"
                                    :disabled="actButton.type==='view'"
                                    placeholder="--- Pilih data ---"
                                >
                                    <template #option="{  nomor, tahun, judul }">
                                        <span>
                                            <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                            <br>Tentang {{ judul }}
                                        </span>
                                    </template>
                                    <template #selected-option="{nomor, tahun, judul }">
                                        <span>
                                            <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                            <br>Tentang {{ judul }}
                                        </span>
                                        <!-- <div style="display: flex; align-items: baseline">
                                            <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                            <em style="margin-left: 0.5rem"
                                            >Tentang {{ judul }}</em
                                            >
                                        </div> -->
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <em v-if="form.errors.has('produk_hukum_id')"
                            class="form-text text-danger mt-0">
                            {{ formModal.errors.produk_hukum_id[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold">
                        Deskripsi
                    </label>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-textarea id="textarea-no-resize" rows="5"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('deskripsi'),
                                    }"
                                    v-model="form.deskripsi" no-resize
                                    :disabled="actButton.type==='view'" placeholder="">
                                </b-form-textarea>
                            </div>
                        </div>
                        <em v-if="form.errors.has('deskripsi')" class="form-text text-danger mt-0">
                            {{ formModal.errors.deskripsi[0] }}
                        </em>
                    </div>
                </div>
                <!-- Form Button -->
                <hr v-if="actButton.type!='view'" />
                <div class="row" v-if="actButton.type!='view'">
                    <div class="col-md-6">
                        <span>
                            <b-button variant="primary" class="mr-2" @click="reset">
                                <i class="fa fa-undo"></i>
                                Reset
                            </b-button>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <span class="float-right">
                            <b-button variant="primary" type="submit" class="mr-2"
                                :disabled="formModal.busy">
                                <span v-if="formModal.busy">
                                    <b-spinner variant="primary" label="Spinning" small></b-spinner>
                                </span>
                                <i class="fa fa-save" v-else></i>
                                Simpan
                            </b-button>
                            <b-button variant="outline-secondary" @click="close">
                                <i class="fa fa-times"></i>
                                Tutup
                            </b-button>
                        </span>
                    </div>
                </div>
            </form>
        </b-modal>

    </div>
</template>



<script>
    /* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars
    import HRetribusi from "../../../components/npwr/HRetribusi.vue";
    import axios from "axios";
    import hapusStorage from "@/helper/hapusLocalStore";
    import konversi from '@/helper/konversi'

    import number from '@/helpers/library/number'

    export default {
        mixins: [
            hapusStorage,
        ],
        components: {
            HRetribusi
        },
        data() {
            return {
                // ==================================================
                // Library
                // --------------------------------------------------
                konversi,
                number,

                // ==================================================
                // Data & Table
                // --------------------------------------------------
                // tools table
                isBusy: false,
                sortBy: null,
                sortDesc: false,
                filter: null,
                filterBy: "all",
                pageOptions: [5, 10, 15, 25],
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
                // table setting
                fields: [{
                        key: "index",
                        label: "No.",
                        tdClass: "text-right"
                    },
                    {
                        key: "perangkat_pd_nama",
                        label: "Perangkat PD",
                        sortable: true,
                        value: 'perangkat_pd.nama'
                    },
                    // {
                    //     key: "ref_rek_5_nama",
                    //     label: "Rincian Rekening",
                    //     sortable: true,
                    //     value: 'ref_rek_5.nama'
                    // },
                    {
                        key: "ref_rek_6_nama",
                        label: "Rincian Rekening",
                        sortable: true,
                        value: 'ref_rek_6.nama'
                    },
                    {
                        key: "tahun",
                        label: "Tahun",
                        sortable: true,
                        value: 'tahun'
                    },
                    {
                        key: "apbd",
                        label: "Target",
                        sortable: true,
                        tdClass: "text-right",
                        value: 'apbd'
                    },
                    {
                        key: "apbd_p",
                        label: "Target Perubahan",
                        sortable: true,
                        tdClass: "text-right",
                        value: 'apbd_p'
                    },
                    {
                        key: "produk_hukum",
                        label: "Produk Hukum",
                        sortable: false,
                        value: ''
                    },
                    {
                        key: "deskripsi",
                        label: "Deskripsi",
                        sortable: false,
                        value: 'deskripsi'
                    },
                    {
                        key: "actions",
                        label: "Opsi"
                    }
                ],
                // actions button
                actButton: {
                    type: '', // view, create, edit, delete
                    edit: false
                },

                // ==================================================
                // Form & FormData for CRUD
                // --------------------------------------------------
                dataPrevious: [],
                form: new Form({
                    id: '',
                    perangkat_pd_id: '',
                    unit_pd_id: '',
                    ref_rek_5_id: '',
                    ref_rek_6_id: '',
                    tahun: '',
                    apbd: 0,
                    apbd_p: 0,
                    produk_hukum_id: '',
                    deskripsi: ''
                }),
                formModal: {
                    type: '',
                    title: '',
                    show: false,
                    busy: false,
                    button: {
                        submit: {
                            disabled: true
                        }
                    },
                    errors: [],
                },
                // options ...
                options: {
                    perangkat_pd: [],
                    // unit_pd: [],
                    ref_rek_5: [],
                    ref_rek_6: [],
                    produk_hukum: []
                },
                // configDatepicker: {
                //     format: "YYYY-MM-DD",
                //     useCurrent: true,
                //     minDate: "1945-01-01"
                // },
            };
        },
        mounted() {
            // this.$store.dispatch("PemerintahDaerah/getPerangkat");
            // this.getTahun();
            // this.create()

            // // ==================================================
            // // TEST
            // // Jangan dihapus sebelun dijadikan function
            // const roundAccurately = (number, desimalPlaces) => Number(Math.round(number + "e" + desimalPlaces) + "e-" + desimalPlaces)
            // alert(roundAccurately(239.575,2));
            // // jika minus
            // alert(-roundAccurately(Math.abs(239.575),2));
            // // ({{ konversi.onFloat(Math.abs(parseFloat(item.sub_total.diff_balance)).toFixed(2)) }})
            // // ==================================================
        },
        computed: {
            // Create an options list from our fields
            fieldOptions() {
                return this.fields
                    .filter(f => f.sortable && f.value)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.value
                        };
                    });
            },
        },
        methods: {
            // ==================================================
            // Data & Table
            // --------------------------------------------------
            // datatable
            myGetData(ctx) {
                // ctx =
                // berasal dari tag <b-table></b-table>
                this.$insProgress.start();
                this.isBusy = true;
                let promise = axios.get("/api/data-induk/apbd/target", {
                    params: {
                        page: ctx.currentPage,
                        perpage: ctx.perPage,
                        sortby: ctx.sortBy,
                        sortdesc: ctx.sortDesc,
                        filter: ctx.filter,
                        filterby: this.filterBy
                    }
                });
                return promise
                    .then(response => {
                        const items = response.data.data;
                        // Data Pagination
                        this.configPagination(response.data.meta);
                        // Here we could override the busy state, setting isBusy to false
                        this.isBusy = false;
                        this.$insProgress.finish();
                        return items;
                    })
                    .catch(error => {
                        // Here we could override the busy state, setting isBusy to false
                        this.isBusy = false;
                        this.$insProgress.fail();
                        if (error.response.status === 401) {
                            // hapus storage mixins
                            this.clearAll();
                        }else{
                            console.log(error)
                        }
                        // Returning an empty array, allows table to correctly handle
                        // internal busy state in case of error
                        return [];
                    });
            },
            // konfigurasi data pagination
            configPagination(data){
                this.totalRows = data.total;
            },
            // reload / refresh data table ketika data filter berubah
            changeFilterBy() {
                if (this.filter) {
                    this.$refs.tableInduk.refresh();
                }
            },
            // reload / refresh data table ketika tombol refresh diklik
            reload() {
                // with id="tableInduk" in tag b-table
                // this.$root.$emit('bv::refresh::table', 'tableInduk')
                // with property ref="tableInduk" in tag b-table
                this.$refs.tableInduk.refresh();
            },

            // ==================================================
            // Form & FormData
            // --------------------------------------------------
            // membersihkan formulir ke data awal
            clean() {
                this.form.reset()
                this.form.clear()
                this.dataPrevious = []
                this.actButton.type = ''
                this.actButton.edit = false
                this.formModal.errors = []
                // stop semua proses ...
                this.formProsesEnd();
            },
            // mengembalikan data formulir ke data sebelumnya
            reset() {
                if (this.actButton.edit) {
                    this.form.reset()
                    this.form.clear()
                    this.form.fill(this.dataPrevious)
                    this.formModal.busy = false
                    this.formModal.errors = []
                } else {
                    this.clean()
                }
            },
            // pengaturan page saat proses sedang berjalan ...
            formProsesStart() {
                this.formModal.busy = true
                this.formModal.button.submit.disabled = true
                this.$insProgress.start();
            },
            // pengaturan page saat proses telah selesai ...
            formProsesEnd() {
                this.$insProgress.finish();
                this.formModal.busy = false
                this.formModal.button.submit.disabled = false
            },
            // --------------------------------------------------
            // create = menampilkan formulir menambah data baru
            view(id) {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'view';
                this.actButton.edit = false;
                this.formModal.title = 'Data Target';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
                // get data by ...
                this.show(id);
                // get options ...
                this.getoptionPerangkatPd()
                this.getoptionRefRek5()
                this.getoptionProdukHukum()
            },
            // create = menampilkan formulir menambah data baru
            create() {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'create';
                this.actButton.edit = false;
                this.formModal.title = 'Tambah Data Target';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
                // get options ...
                this.getoptionPerangkatPd()
                this.getoptionRefRek5()
                this.getoptionProdukHukum()
            },
            // edit = menampilkan formulir merubah data lama
            edit(id) {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'edit';
                this.actButton.edit = true;
                this.formModal.title = 'Edit Data Target';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
                // get data by ...
                this.show(id);
                // get options ...
                this.getoptionPerangkatPd()
                this.getoptionRefRek5()
                this.getoptionRefRek6(this.form.ref_rek_5_id)
                this.getoptionProdukHukum()
            },
            // delete = menampilkan formulir atau pesan peringatan penghapusan data
            deleteConfirm(id, data) {
                this.actButton.type = 'delete';
                this.formModal.title = 'Hapus Data Target';
                this.$swal({
                    icon: "question",
                    title: "<h3 class='mb-0'><strong>Konfirmasi Penghapusan Data</strong></h3>",
                    text: "Anda yakin akan menghapus data " + data.singkatan + " ?",
                    showCloseButton: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ya, hapus !",
                    cancelButtonText: "Cancel",
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary mr-2',
                        cancelButton: "btn btn-default"
                    },
                    timer: 5000,
                }).then(result => {
                    if (result.isConfirmed) {
                        this.destroy(id);
                    }
                });
            },
            // close = menutup formulir menambah atau merubah data
            close() {
                this.clean()
                this.formModal.show = false;
                // or ...
                // this.$root.$emit("bv::hide::modalForm", "modalForm");
            },

            // ==================================================
            // Eksekusi Data ke BackEnd
            // --------------------------------------------------
            convertCurencyToInteger(){
                this.form.apbd = number.formatIDtoEN(this.form.apbd);
                this.form.apbd_p = number.formatIDtoEN(this.form.apbd_p);
            },
            // store
            store() {
                this.formProsesStart();
                this.convertCurencyToInteger();
                this.form
                    .post("/api/data-induk/apbd/target")
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        // --------------------------------------------------
                        // alert success 1 ...
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info btn-sm',
                            },
                            timer: 5000,
                        });
                        this.clean();
                        this.close();
                        // this.clean();
                        // --------------------------------------------------
                        // alert success 2 ...
                        // tambah data baru atau tutup form ?
                        // this.$swal({
                        //     icon: "success",
                        //     title: "<h3 class='mb-0'><strong>"
                        //         +response.data.status.title+
                        //         "</strong></h3>",
                        //     text: response.data.status.message,
                        //     showConfirmButton: true,
                        //     showCancelButton: true,
                        //     confirmButtonText: "Tambah Data Baru",
                        //     cancelButtonText: "Tutup",
                        //     buttonsStyling: false,
                        //     customClass: {
                        //         confirmButton: "btn btn-info mr-2",
                        //         cancelButton: "btn btn-default"
                        //     },
                        //     timer: 5000,
                        // }).then(result => {
                        //     if (result.isConfirmed) {
                        //         this.clean()
                        //     }else{
                        //         this.close()
                        //     }
                        // });
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 422) {
                            this.formModal.errors = error.response.data;
                        } else if (error.response.status === 401) {
                            // hapus token mixins
                            // this.clearAll();
                        }else{
                            console.log(error)
                        }
                    });
            },
            // show data berdasarkan id
            show(id) {
                axios
                    .get("/api/data-induk/apbd/target/" + id)
                    .then((response) => {
                        // getoption by ...
                        this.getoptionRefRek6(response.data.data.ref_rek_5_id)
                        // return data ...
                        this.dataPrevious = response.data.data;
                        this.form.fill(this.dataPrevious);
                        this.formModal.errors = [];
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            // hapus token mixins
                            this.clearAll();
                        }else{
                            console.log(error)
                        }
                    });
            },
            // update
            update(id) {
                this.formProsesStart();
                this.convertCurencyToInteger();
                this.form
                    .put("/api/data-induk/apbd/target/" + id)
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info',
                            },
                            timer: 5000,
                        });
                        this.clean();
                        this.close();
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 422) {
                            this.formModal.errors = error.response.data;
                        } else if (error.response.status === 401) {
                            // ...
                        } else if (error.response.status === 405) {
                            this.$swal({
                                icon: "error",
                                title: "<h3 class='mb-0'><strong>" +
                                    response.data.status.title +
                                    "</strong></h3>",
                                text: error.response.data.status.message,
                                showConfirmButton: true,
                                showCloseButton: true,
                                confirmButtonText: "OKE",
                                allowOutsideClick: false,
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: 'btn btn-info',
                                },
                                timer: 5000,
                            });
                        }else{
                            console.log(error)
                        }
                    });
            },
            // destroy
            destroy(id) {
                this.formProsesStart();
                axios
                    .delete("/api/data-induk/apbd/target/" + id)
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info',
                            },
                            timer: 5000,
                        });
                        this.clean();
                        this.close();
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 401) {
                            // ...
                        } else if (error.response.status === 405) {
                            this.$swal({
                                icon: "error",
                                title: "<h3 class='mb-0'><strong>" +
                                    response.data.status.title +
                                    "</strong></h3>",
                                text: error.response.data.status.message,
                                showConfirmButton: true,
                                showCloseButton: true,
                                confirmButtonText: "OKE",
                                allowOutsideClick: false,
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: 'btn btn-info',
                                },
                                timer: 5000,
                            });
                        }else{
                            console.log(error)
                        }
                    });
            },

            // ==================================================
            // Memdapatkan Data Opsi/Pilihan ke BackEnd
            // --------------------------------------------------
            getoptionPerangkatPd(){
                axios.get('/api/pemerintah-daerah/perangkat-pd/getoption')
                .then( response => {
                    this.options.perangkat_pd = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        // hapus token mixins
                        this.clearAll();
                    }else{
                        console.log(error)
                    }
                })
            },
            getoptionRefRek5(){
                axios.get('/api/referensi/rekening-5/getoption/rekening-4', {
                    params: {
                        rekening4: [210,211,212]
                    }
                })
                .then( response => {
                    this.options.ref_rek_5 = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        // hapus token mixins
                        this.clearAll();
                    }else{
                        console.log(error)
                    }
                })
            },
            getoptionRefRek6(ref_rek_5_id){
                if(ref_rek_5_id){
                    axios.get('/api/referensi/rekening-6/getoption/rekening-5-' + ref_rek_5_id)
                    .then( response => {
                        this.options.ref_rek_6  = response.data.data;
                        if(ref_rek_5_id != this.dataPrevious.ref_rek_5_id){
                            this.form.ref_rek_6_id  = ""
                        }
                    }).catch(error => {
                        if (error.response.status === 401) {
                            // hapus token mixins
                            this.clearAll();
                        }else{
                            console.log(error)
                        }
                    })
                }else{
                    this.options.ref_rek_6  = []
                    this.form.ref_rek_6_id  = ""
                }

            },
            getoptionProdukHukum(){
                axios.get('/api/data-induk/jdih/produk-hukum/getoption')
                .then( response => {
                    this.options.produk_hukum = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        // hapus token mixins
                        this.clearAll();
                    }else{
                        console.log(error)
                    }
                })
            },

            // ==================================================
            // Library
            // --------------------------------------------------
            inputCurrency(e){
                let number      = e.target.value ? e.target.value.toString().replaceAll('.','') : 0;
                number          = parseInt(number)
                e.target.value  = konversi.onInput(number)
            },
            // input number ...
            isNumberKey(evt){
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            // format currency ...
            formatMoney(number, decPlaces, decSep, thouSep) {
                decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                decSep = typeof decSep === "undefined" ? "." : decSep;
                thouSep = typeof thouSep === "undefined" ? "," : thouSep;
                var sign = number < 0 ? "-" : "";
                var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
                var j = (j = i.length) > 3 ? j % 3 : 0;

                return sign +
                    (j ? i.substr(0, j) + thouSep : "") +
                    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                    (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
            }
        }
    };
</script>